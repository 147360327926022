import type {
  GameInProviderGamingResApi,
  ProviderGamingListResApi,
  ResponseApiGamingBetTransactionInterface,
} from 'types/gaming';
import type { KeyedMutator } from 'swr';
import type { AxiosResponse, Method } from 'axios';
import { useCallback } from 'react';
import { objectify } from 'radash';
import type { PaginationResponseInterface } from '../types/etc';
import type { Config } from './use-request';
import useRequest from './use-request';
import { NEXT_PUBLIC_S3_BUCKET_ASSETS_URL } from '~constants/etc';

export const useGameListInGamingProvider = (
  currency?: string,
  provider?: string,
  config?: Config<GameInProviderGamingResApi[]>,
): {
  data: GameInProviderGamingResApi[] | undefined;
  isLoading: boolean;
  mutate: KeyedMutator<AxiosResponse<GameInProviderGamingResApi[]>>;
} => {
  const { data: games, ...rest } = useRequest<GameInProviderGamingResApi[]>(
    provider
      ? {
          method: 'GET' as Method,
          url: `/gaming/${provider}/games`,
          params: {
            currency,
          },
        }
      : null,
    config,
  );

  const gamesMapped =
    games?.map((res) => {
      return {
        ...res,
        thumbnail: `${NEXT_PUBLIC_S3_BUCKET_ASSETS_URL}/main/media/image/${res.provider}/games/${res.gameCode}/thumbnail.webp`,
      };
    }) || [];

  return {
    ...rest,
    data: gamesMapped,
  };
};

export const useAllGames = (
  params?: {
    currency?: string;
  },
  config?: Config<PaginationResponseInterface<ProviderGamingListResApi[]>>,
) => {
  return useRequest<PaginationResponseInterface<ProviderGamingListResApi[]>>(
    {
      url: '/gaming',
      params,
    },
    {
      revalidateOnFocus: true,
      ...config,
    },
  );
};

export const useGamingBetTransactions = (
  query: any = {},
  config?: Config<ResponseApiGamingBetTransactionInterface>,
) => {
  const provider = query.provider ? { provider: query.provider } : null;

  return useRequest<ResponseApiGamingBetTransactionInterface>(
    {
      url: '/gaming/bet-transactions',
      params: {
        limit: 100,
        startAt: query.startAt,
        endAt: query.endAt,
        ...provider,
      },
    },
    { ...config, revalidateOnFocus: true },
  );
};

export const useGamingInfoHelper = (currency: string) => {
  const { data } = useAllGames({
    currency,
  });
  const getGamingProviderImage = useCallback(
    (provider: string) => {
      const gamingProvider = data?.records?.find((i) => i.id === provider);
      if (!gamingProvider) {
        return;
      }
      return objectify(
        gamingProvider?.images,
        (f) => f.name as string,
        (f) => f.value as string,
      );
    },
    [data],
  );
  return {
    getGamingProviderImage,
  };
};
